import {HttpErrorResponse, HttpInterceptorFn, HttpStatusCode} from '@angular/common/http';
import {throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

export const authInterceptor: HttpInterceptorFn = (request, next) => {
  return next(request).pipe(
    catchError((error: HttpErrorResponse) => {
      if (!(error.error instanceof Error) && error.status === HttpStatusCode.Unauthorized) {
        /* @todo:: Тут надо реализовать логику обработки запросов, вернувшихся с ошибкой авторизации
        *    (например, отображать пользователю уведомление, что он не авторизован, или
        *    открывать модальное окно авторизации) */
        console.error(`Текущему пользователю запрещен доступ к запрашиваемым данным.`);
      }

      // Вернуть новый ответ
      //return of(new HttpResponse({body: [{name: "Default value..."}]}));

      // Пробросить ошибку выше
      return throwError(() => error);

      // Просто вернуть "ничего"
      // return EMPTY;
    })
  );
}
