import {inject, Injectable} from '@angular/core';
import {SignalRService} from "./signal-r.service";
import {environment} from "../../../../environments/environment";
import {Subject, takeUntil} from "rxjs";
import {NotificationDto} from "../../common/models/dtos/notification";
import {MessageService} from "primeng/api";
import {NotificationTypeEnum} from "../../common/models/enums/notification-type.enum";
import {AchievementsService} from "../../../features/cpanel/services/achievements.service";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  signalR = inject(SignalRService);
  messageService = inject(MessageService);
  achievementsService = inject(AchievementsService);
  private ngUnsubscribe = new Subject<void>();

  start() {
    this.signalR.startConnection(environment.notificationsHubUrl);
    this.subscribeToNotifications();
  }

  public stop() {
    this.signalR.disconnect(environment.notificationsHubUrl);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private subscribeToNotifications() {
    this.signalR.on(environment.notificationsHubUrl, 'NotifyUser')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((notification: NotificationDto) => {
        switch (notification.type) {
          case NotificationTypeEnum.CommonNotification:
            this.messageService.add({severity: 'info', detail: notification.message});
            break;
          case NotificationTypeEnum.AchievementNotification:
            this.achievementsService.showAchievementNotification(notification);
            break;
        }
      });
  }
}
