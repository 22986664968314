import {Component, effect, Inject, inject, LOCALE_ID} from '@angular/core';
import {CommonModule, DOCUMENT, registerLocaleData} from '@angular/common';
import {RouterOutlet} from '@angular/router';
import {AuthService} from "./core/auth/services/auth.service";
import {UserService} from "./core/auth/services/user.service";
import {PermissionService} from "./core/auth/services/permission.service";
import {LoaderComponent} from "./core/common/components/loader/loader.component";
import {ToastModule} from "primeng/toast";
import {NotificationsService} from "./core/shared/services/notifications.service";
import {PrimeNGConfig} from "primeng/api";
import {ru} from "../assets/i18n/ru";
import localeRu from '@angular/common/locales/ru';

registerLocaleData(localeRu);

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, LoaderComponent, ToastModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [
    { provide: LOCALE_ID, useValue: 'ru' }
  ]
})
export class AppComponent {
  auth = inject(AuthService);
  userService = inject(UserService);
  permissionService = inject(PermissionService);
  notificationsService = inject(NotificationsService);
  primengConfig = inject(PrimeNGConfig);

  constructor(@Inject(DOCUMENT) private document: Document) {
    const localStorage = this.document.defaultView?.localStorage;
    if (!!localStorage) {
      this.auth.validateToken();
      this.primengConfig.setTranslation(ru);
    }

    effect(async () => {
      if (this.auth.isAuthorized()) {
        await Promise.all([this.userService.defineUser(), this.permissionService.definePermissions()]);
        this.notificationsService.start();
      } else {
        this.userService.resetUser();
        this.permissionService.resetPermissions();
        this.notificationsService.stop();
      }
    }, { allowSignalWrites: true });
  }
}
