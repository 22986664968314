import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {provideRouter} from '@angular/router';
import {routes} from './app.routes';
import {provideClientHydration} from '@angular/platform-browser';
import {
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi, withXsrfConfiguration
} from "@angular/common/http";
import {JwtModule} from "@auth0/angular-jwt";
import {tokenGetter} from "./core/auth/configs/auth.config";
import {environment} from "../environments/environment";
import {authInterceptor} from "./core/common/interceptors/auth.interceptor";
import {loaderInterceptor} from "./core/common/interceptors/loader.interceptor";
import {provideAnimations} from "@angular/platform-browser/animations";
import {ConfirmationService, MessageService} from "primeng/api";
import {httpErrorInterceptor} from "./core/common/interceptors/http-error.interceptor";

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(routes),
    provideClientHydration(),
    provideHttpClient(
      withFetch(),
      withInterceptors([authInterceptor, loaderInterceptor, httpErrorInterceptor]),
      withInterceptorsFromDi(),
      withXsrfConfiguration({
        cookieName: 'MY-XSRF-TOKEN',
        headerName: 'X-MY-XSRF-TOKEN'
      })
    ),
    importProvidersFrom([
      JwtModule.forRoot({
        config: {
          tokenGetter: tokenGetter,
          allowedDomains: environment.allowedApiDomains
        }
      })
    ]),
    MessageService,
    ConfirmationService
  ]
};
