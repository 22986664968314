import {HttpErrorResponse, HttpInterceptorFn, HttpStatusCode} from '@angular/common/http';
import {catchError} from "rxjs/operators";
import {throwError} from "rxjs";
import {inject} from "@angular/core";
import {MessageService} from "primeng/api";

export const httpErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const message = inject(MessageService);

  return next(req).pipe(
    catchError((err: HttpErrorResponse) => {
      if (err.status !== HttpStatusCode.Unauthorized) {
        message.add({ severity: 'error', summary: 'Неизвестная ошибка', detail: 'Пожалуйста, повторите запрос позже или обратитесь в тех. поддержку' });
      }
      return throwError(() => err);
    })
  );
};
