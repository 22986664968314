import {Routes} from '@angular/router';
import {UserRole} from "./core/auth/models/enums/user-role.enum";
import {authGuardFunc} from "./core/auth/guards/authGuardFunc";

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'cpanel/home',
  },
  {
    path: 'cpanel',
    loadComponent: () => import('./features/cpanel/c-panel.component').then(c => c.CPanelComponent),
    canActivate: [authGuardFunc],
    children: [
      {
        path: 'home',
        loadComponent: () => import('./features/cpanel/components/home/home.component').then(c => c.HomeComponent)
      },
      {
        path: 'games',
        loadComponent: () => import('./features/cpanel/components/my-games/my-games.component').then(c => c.MyGamesComponent)
      },
      {
        path: 'games/:id',
        loadComponent: () => import('./features/cpanel/components/game/game.component').then(c => c.GameComponent)
      },
      {
        path: 'new-game',
        loadComponent: () => import('./features/cpanel/components/create-game/create-game.component').then(c => c.CreateGameComponent)
      },
      {
        path: 'search-games',
        loadComponent: () => import('./features/cpanel/components/search-games/search-games.component').then(c => c.SearchGamesComponent)
      },
      {
        path: 'settings',
        loadComponent: () => import('./features/cpanel/components/user-settings/user-settings.component').then(c => c.UserSettingsComponent),
        children: [
          {
            path: 'profile',
            loadComponent: () => import('./features/cpanel/components/profile-settings/profile-settings.component').then(c => c.ProfileSettingsComponent)
          },
          {
            path: 'balance',
            loadComponent: () => import('./features/billing/components/balance/balance.component').then(c => c.BalanceComponent)
          }
          // Пока функционал клубов решили убрать
          /*{
            path: 'clubs',
            loadComponent: () => import('./features/cpanel/components/clubs-settings/clubs-settings.component').then(c => c.ClubsSettingsComponent)
          }*/
        ]
      },
      {
        path: 'leaderboard',
        loadComponent: () => import('./features/cpanel/components/leaderboard/leaderboard.component').then(c => c.LeaderboardComponent)
      },
      {
        path: 'rating',
        loadComponent: () => import('./features/cpanel/components/rating/rating.component').then(c => c.RatingComponent)
      },
      {
        path: 'cases',
        loadComponent: () => import('./features/cpanel/components/cases/cases.component').then(c => c.CasesComponent)
      },
      {
        path: 'payment-success',
        loadComponent: () => import('./features/billing/components/payment-success/payment-success.component').then(c => c.PaymentSuccessComponent)
      }
    ]
  },
  {
    path: 'sign-in-as',
    loadComponent: () => import('./core/auth/components/sign-in-as/sign-in-as.component').then(c => c.SignInAsComponent)
  },
  {
    path: 'trainee/sign-in',
    loadComponent: () => import('./core/auth/components/sign-in/sign-in.component').then(c => c.SignInComponent),
    data: {
      role: UserRole.Trainee
    }
  },
  {
    path: 'trainer/sign-in',
    loadComponent: () => import('./core/auth/components/sign-in/sign-in.component').then(c => c.SignInComponent),
    data: {
      role: UserRole.Trainer
    }
  },
  {
    path: 'introduce',
    loadComponent: () => import('./core/auth/components/introduce/introduce.component').then(c => c.IntroduceComponent),
    canActivate: [authGuardFunc]
  },
  {
    path: '**',
    loadComponent: () => import('./core/common/components/not-found/not-found.component').then(c => c.NotFoundComponent)
  }
];
