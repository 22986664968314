import {CanActivateFn, Router} from '@angular/router';
import {inject, PLATFORM_ID} from "@angular/core";
import {AuthService} from "../services/auth.service";
import {isPlatformBrowser} from "@angular/common";

export const authGuardFunc: CanActivateFn = (route, state) => {
  const auth = inject(AuthService);
  const router = inject(Router);
  const platformId = inject(PLATFORM_ID);

  if (isPlatformBrowser(platformId)) {
    if (!auth.isAuthorized()) {
      return router.parseUrl('/sign-in-as');
    }
    return true;
  } else {
    return false;
  }
};
