import { HttpInterceptorFn } from '@angular/common/http';
import {inject} from "@angular/core";
import {LoaderService} from "../services/loader.service";
import {finalize} from "rxjs/operators";

export const loaderInterceptor: HttpInterceptorFn = (req, next) => {
  let totalRequests = 0;
  const loadingService = inject(LoaderService);
  const exceptedMethodNames: string[] = [
    'getUserIdByTelegramUsername'
  ];

  if (exceptedMethodNames.find(name => req.url.search(name) !== -1))
    return next(req);

  totalRequests++;
  loadingService.setLoading(true);
  return next(req).pipe(
    finalize(() => {
      totalRequests--;
      if (totalRequests == 0) {
        loadingService.setLoading(false);
      }
    })
  );
};
