import {Component, inject, ViewEncapsulation} from '@angular/core';
import {LoaderService} from "../../services/loader.service";
import {ProgressSpinnerModule} from "primeng/progressspinner";

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [
    ProgressSpinnerModule
  ],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
  encapsulation: ViewEncapsulation.ShadowDom
})
export class LoaderComponent {
  loader = inject(LoaderService);
}
